.container {
  display: flex;
  justify-content: center;
  width: 65%;
  height: 25%;
}
.canvas {
  height: 300px !important;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 100%;
  }
}
