body {
  background-color: white;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image {
  width: 370px;
  margin-top: 50px;
}
.imageJuntos {
  width: 370px;
  margin-top: 50px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 770px) {
  .container {
    margin: 0 10%;
  }

  .image {
    width: 100%;
  }
  .imageJuntos {
    width: 100%;
    padding-bottom: 20px;
  }
}
.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: tomato;
  color: white;
}

.language {
  font: Arial;
}
