.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.card {
  margin: 0 2% !important;
}

.infected {
  border-bottom: 10px solid #3333ff;
}

.recovered {
  border-bottom: 10px solid #008000;
}

.deaths {
  border-bottom: 10px solid #ff0000;
}

@media only screen and (max-width: 770px) {
  .card {
    margin: 2% 0 !important;
  }
}

.progressBarfont {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
