.formControl {
  width: 100%;
  margin-bottom: 30px !important;
  margin: 1px;
  font-size: larger !important;
}

.InputLabel {
  font-size: larger !important;
}
.NativeSelect {
  font-size: larger !important;
  text-align-last: center;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
